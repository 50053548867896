import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Sidebar from '../blocks/sidebar';

import { navMap } from './helpers';

function NavLi(props) {
  const { canView, id, path, label, auth } = props;
//!Проверка роли и показ меню
  const allowed = !canView.length
    ? true
    : canView?.some((role) => auth.user.roles.some((user) => role === user.roleName));
  const className = ({ isActive }) => `${isActive ? 'active' : ''}`;

  if (!allowed) {
    return null;
  }

  if (!path) {
    return (
      <li key={id}>
        <div>{label}</div>
      </li>
    );
  }

  //!

  return (
    <li key={id}>
      <NavLink to={path} className={className}>
        {label}
      </NavLink>
    </li>
  );
}

export default function Nav() {
  const auth = useSelector((state) => state.authReducer);

  return (
    <Sidebar>
      <div className="menu-navbar">
        <ul className="list-unstyled">
          {navMap.map((el, index) => (
            <NavLi {...el} key={`${el.id}-${index}`} auth={auth} />
          ))}
        </ul>
      </div>
    </Sidebar>
  );
}
