import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { Outlet, useLocation } from 'react-router-dom';

import { roleUpdate } from '../../store/actions/authActions';
import Container from '../atoms/container';
import HeaderAdmin from '../blocks/headerAdmin';
//import GetAccess from '../formik/forms/getAccess';
import Nav from '../Nav/Nav';
import DemoLayot from '../pages/demolayot';

const navMap = [
  {
    id: '1',
    path: '/grade/projects',
    label: 'Проекты',
  },
];

function AuthLayout() {
  const auth = useSelector((state) => state.authReducer);
  const isDemoRole = auth?.user?.roles?.some((el) => el.roleName === 'demo-role');
  const location = useLocation();
  const [cookies] = useCookies();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(roleUpdate(cookies.Role));
  }, [cookies.Role]);
  console.log(cookies.Role)

  const isInfoPage = location.pathname.includes('information');

  if (!auth.isLoggedIn) {
    return <Navigate to="/" />;
  }
console.log(isDemoRole)
  if (isDemoRole) {
    //console.log('Demo')
    const navDemo = [
      {
      id: '1',
      path: '',
      label: ''
      }
    ]
    return (
      <React.Fragment>
        <Nav isRenderMenu={true} />
        <Container>
          <HeaderAdmin navMap={[]} />
          <DemoLayot />
        </Container>
      </React.Fragment>
    );
  }
/*
  else if (auth?.isLoggedIn && !isInfoPage) {
    return (
      <React.Fragment>
        <Nav isRenderMenu={true} />
        <Container>
          <HeaderAdmin navMap={navMap} />
          <GetAccess />
          <Outlet />
        </Container>
      </React.Fragment>
    );
  }
*/
  else {
  return (
    <React.Fragment>
      <Nav isRenderMenu={true} />
      <Container>
        <Outlet />
      </Container>
    </React.Fragment>
  );
}
}

export default AuthLayout;
