import styled from 'styled-components';

export const StyledIconButton = styled.button`
  background: transparent;
  border: none;

  & .label {
    text-align: center;
  }
`;
