import styled from "styled-components";

const getWidth = ({ size = 0 }) => (size ? `${100 / size}%` : "auto");

export const StyledGrid = styled.div`
  display: flex;
  justify-content: ${({ justifyContent = "flex-start" }) => justifyContent};
  background-color: ${({ background }) => background?.color ?? "transparent"};
`;

export const StyledItem = styled.div`
  flex-basis: ${(props) => getWidth(props)};
  flex-grow: ${({ grow }) => grow ?? 0};
  flex-shrink: ${({ shrink }) => shrink ?? 0};
  width: ${(props) => getWidth(props)};
`;
