import React from 'react';

import { StyledButton } from './button.styled.js';

function ButtonInner(props) {
  const { label, children } = props;

  if (label) {
    return <span className="label">{label}</span>;
  }

  return children;
}

export default function Button({ label, children, variant, ...ownerprops }) {
  return (
    <StyledButton {...ownerprops} variant={variant} type="button">
      <ButtonInner label={label} children={children} />
    </StyledButton>
  );
}
