import React from 'react';

import MainLogo from '../../../assets/svg/logo';

import * as Styled from './styled';

export default function Nav({ children }) {
  return (
    <Styled.MainNavigation>
      <Styled.Navbar>
        <Styled.LogoWrap>
          <MainLogo />
        </Styled.LogoWrap>
        <div className="menu-nav">{children}</div>
        <div className="logo">
          <Styled.SVGLogoBottom />
          {process.env.REACT_APP_VERSION ? <Styled.Version>{process.env.REACT_APP_VERSION}</Styled.Version> : ''}
        </div>
      </Styled.Navbar>
    </Styled.MainNavigation>
  );
}
