import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as nsiActions from '../../../../../../store/actions/NSIActions';

function getRootName(name) {
  return (arr) => {
    if (!arr?.length) {
      return {};
    }

    return {
      value: {
        name,
      },
      children: arr,
    };
  };
}

export default function useGetObjectOfOccurrence() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(nsiActions.getInstrumentsAndEquipments);
    dispatch(nsiActions.getMaterials);
    dispatch(nsiActions.getWorkPlaces2);
    dispatch(nsiActions.getBioObjects);
    dispatch(nsiActions.getMachinery);
  }, []);

  const nSIReducer = useSelector((state) => state.NSIReducer);
  const instrumentsAndEquipments =
    nSIReducer?.instrumentsAndEquipments?.flat(Infinity);
  const workPlaces2 = nSIReducer?.workPlaces2?.flat(Infinity);
  const bioObjects = nSIReducer?.bioObjects?.flat(Infinity);
  const machinery = nSIReducer?.machinery?.flat(Infinity);
  const materials = nSIReducer?.materials?.flat(Infinity);

  const response = React.useMemo(() => {
    const childInstrumentsAndEquipments = getRootName(
      'Инструменты и приспособления',
    );
    const childMaterials = getRootName('Материалы и сырье');
    const childWorkPlaces2 = getRootName('Здания и сооружения');
    const childBioObjects = getRootName(
      'Биологические и антропогенные объектыя',
    );
    const childMachinery = getRootName('Оборудование');

    return [
      childInstrumentsAndEquipments(instrumentsAndEquipments),
      childMaterials(materials),
      childWorkPlaces2(workPlaces2),
      childBioObjects(bioObjects),
      childMachinery(machinery),
    ];
  }, [instrumentsAndEquipments, materials, workPlaces2, bioObjects, machinery]);

  return response;
}
