import styled from 'styled-components';

export const StyledLinkPrint = styled.a`
  text-decoration: none;
  white-space: nowrap;

  & .label {
    color: #52575c;
    padding-left: ${({ typeButton }) => (typeButton === 'text' ? 0 : 8)}px;
    text-align: ${({ typeButton }) =>
      typeButton === 'text' ? 'center' : 'left'};
    font-weight: ${({ styles }) => styles?.fontWeight ?? '400'};
    font-size: 15px;
  }
`;
