export const getCodeSplit = (code) => {
  if (!!code?.length) {
    return code?.split('.').length;
  }

  return null;
};

export const isValue = (el) => (el?.value?.name || el?.name)?.trim().length;

export function isMeasure(measure) {
  return !!measure && measure !== '0';
}

export function getRecurSearch(data, query) {
  return data?.map((el) => {
    const valueName = el?.value?.name ?? el?.name;

    if (!!el?.children?.length) {
      return { ...el, children: getRecurSearch(el?.children, query) };
    }

    return valueName?.toLowerCase().includes(query?.toLowerCase()) ? el : null;
  });
}
