import React, { useState } from 'react';

import polygonClose from '../../../image/Polygon_close.svg';
import polygonOpen from '../../../image/Polygon_open.svg';
import SimpleModal from '../SimpleModal';

import s from './DictionaryModal.module.css';

function isMeasure(measure) {
  return !!measure && measure !== '0';
}

const DictionaryModal = (props) => {
  const {
    paramCode = 'code',
    withCode,
    show,
    title,
    caption,
    onHide,
    onSave,
    dictionary = [],
    placeholder = 'введите текст',
    ignoreList = [],
    isSiz,
  } = props;

  const [open, setOpen] = useState(true);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState(null);

  const handleElementClick = (element) => {
    return () => {
      setSelected(getRootElement(element));
    };
  };

  const handleSearchTextChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSave = () => {
    if (!selected) {
      return;
    }

    if (onSave) {
      onSave(selected);
    }
    onHide();
  };

  const toggleOpen = () => {
    setOpen((state) => !state);
  };

  const getRootElement = (element) => {
    /** @todo на бэке привести к одному виду */
    return element?.value || element;
  };

  const inIgnoreList = (item) => {
    if (!ignoreList.length) {
      return false;
    }
    return !!ignoreList.find((listItem) => listItem.name === item.name);
  };

  const renderElement = React.useCallback(
    (element) => {
      const el = getRootElement(element);

      if (inIgnoreList(el)) {
        return null;
      }
      const isVisible =
        !search ||
        (search &&
          getRootElement(element)
            ?.name?.toLowerCase()
            .includes(search?.toLowerCase()));
      if (!isVisible) {
        return null;
      }

      const className = `typesOfJobsList ${
        selected?.id === el?.id ? 'typesOfJobsListActive' : ''
      }`;

      const renderName = isMeasure(el.name) ? el?.name : '';

      const renderWithCode =
        withCode && isMeasure(el.name)
          ? `${el[paramCode]} ${el.name}`
          : renderName;

      const showClothes = isMeasure(el.clothes) ? `- ${el.clothes}` : '';

      const renderSiz = isSiz
        ? `${renderWithCode} ${showClothes}`
        : renderWithCode;

      return (
        <div className="cursor-pointer" key={el.id}>
          <div className={className} onClick={handleElementClick(element)}>
            {renderSiz}
          </div>
        </div>
      );
    },
    [selected, search, ignoreList],
  );

  const renderedDictionary = React.useMemo(() => {
    if (!dictionary?.length) {
      return [];
    }

    const renderRecursiveDictionary = (dictionary) => {
      return dictionary?.map((element) => (
        <>
          {renderElement(element)}
          {renderRecursiveDictionary(element.children)}
        </>
      ));
    };

    return renderRecursiveDictionary(dictionary);
  }, [dictionary, renderElement]);

  return (
    <SimpleModal
      show={show}
      title={title}
      onHide={onHide}
      onSave={handleSave}
      size="lg">
      <div className="modal-body">
        <form>
          <div className={`${s.formContainer} shadow px-4 py-4 fz-13`}>
            {open ? (
              <div className="cursor-pointer">
                <img
                  className="p-2"
                  src={polygonOpen}
                  onClick={toggleOpen}
                  alt=""
                />
                <label htmlFor="typesworks">{caption}</label>
                <input
                  name="typesworks"
                  type="text"
                  autoFocus
                  onChange={handleSearchTextChange}
                  placeholder={placeholder}
                  style={{
                    backgroundColor: 'transparent',
                    border: '0',
                    borderBottom: '1px solid grey',
                    marginLeft: '6px',
                  }}
                />
                {renderedDictionary}
              </div>
            ) : (
              <div className="cursor-pointer">
                <img
                  className="p-2"
                  src={polygonClose}
                  onClick={toggleOpen}
                  alt=""
                />
                {caption}
              </div>
            )}
          </div>
        </form>
      </div>
    </SimpleModal>
  );
};

DictionaryModal.propTypes = {};

export default DictionaryModal;
