import React from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import axios from '../../../../axios/axios';
import * as hooks from '../../../../hooks';
import { logWarn } from '../../../../utils/log';
import Flex from '../../../atoms/flex';
import InputSearch from '../../../atoms/search';
import Spinner from '../../../spinner';

import TitleEdit from './fields/titleEdit';
import WorkplaceInput from './fields/workplaceInput';
import AddDivision from './addDivision';
import AddWorkplace from './addWorkplace';
import EditStructure from './editStructure';
import EditWorkplace from './editWorkplace';
import { usePostStructure } from './hooks';
import StructuralUnits from './structuralUnits';
import * as Styled from './styled';


const { useBoolean } = hooks;

export default function ListOfJobs(props) {
  const { id, onPostProject } = props;
  const [importLoading, onImportLoading] = useBoolean(false);
  const [showAddWorkplace, onShowAddWorkplace] = useBoolean(false);
  const [showEditWorkplace, onShowEditWorkplace] = useBoolean(false);
  const [showAddDivision, onShowAddDivision] = useBoolean(false);
  const [showTitleEdit, onShowTitleEdit] = useBoolean(false);

  const [rightName, setRightName] = React.useState(null);

  const formikListOfJobs = useFormik({
    enableReinitialize: true,
    initialValues: {
      left: [],
      right: [],
      title: {},
      prevTitle: {},
      division: {},
      addWorkplace: {},
      structureEl: {},
    },
  });

  const {
    // dataStructure,
    // setDataStructure,
    onPostStructureFind,
    onDeleteStructure,
    onPostStructure,
    onPostEditStructure,
    onDeleteStructureWorkplace,
    onPostStructureWorkplace,
    onPostEditworkplace,
    onPostAddStructure,
    onPostChangeStructure,
  } = usePostStructure({
    id,
    formik: formikListOfJobs,
    onShowTitleEdit,
    onShowEditWorkplace,
    onShowAddDivision,
  });

  React.useEffect(() => {
    onPostStructure();
  }, []);

  const { right } = formikListOfJobs?.values ?? {};
  const token = localStorage.getItem('token');

  const handleUploadClick = () => {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    input.onchange = (e) => {
      if (e.target.files) {
        if (!e.target.files[0]) {
          return;
        }
        onImportLoading.on();
        const formData = new FormData();

        formData.append("file", e.target.files[0]);
//TODO: Моя редакция отправка списка рабочих мест
/*
        fetch(`http://158.160.65.149/api/uploadworkpace/?uid=${id}&bearer=${token}`, {
          method: 'POST',
          body: e.target.files[0],

          headers: {
            'content-type': e.target.files[0].type,
            'content-length': `${e.target.files[0].size}`,
          },
        })
        */
        axios.post(
          `/api/uploadworkpace/?uid=${id}&bearer=${token}`,
          formData,
          {
              headers: {
                  "Content-type": "multipart/form-data",
                  'Access-Control-Allow-Origin': '*'
              },
              mode: "cors",
          }
      )
    //! Конец правки
          .then((x) => {
            if (x.status === 200) return x.json()
            return x.text()
          })
          .then((x) => {
            if (x?.Status) {
              toast['success'](x?.Status);
              onPostProject();
              onPostStructure();
              onImportLoading.off();
            } else {
              onImportLoading.off();
              toast['error'](x);

              logWarn({
                errorNumber: '1685587487927',
                errorMessage: `${x}`,
              });
              return undefined;
            }
          })
          .catch((x) =>  {
            onImportLoading.off();
            toast['error'](x);

            logWarn({
              errorNumber: '1685587487927',
              errorMessage: `${x}`,
            });
            return undefined;
          });
      }
    };

    input.click();
  };

  const onFindWorkplace = (e) => {
    const eTarget = e.target;
    const { right } = formikListOfJobs.values ?? {};
    const checkNameValue = (name) => name?.toLowerCase().includes(eTarget.value?.toLowerCase());

    const findWorkplace = right?.map((el) => {
      if (!checkNameValue(el.name)) {
        return {
          id: el.id,
          name: el.name,
          num: el.serial_nummber
        };
      }

      if (checkNameValue(el.name)) {
        return {
          ...el,
          active: true,
        };
      }

      return el;
    });

    if (eTarget.value.length < 3) {
      return null;
    }

    formikListOfJobs.setFieldValue('right', findWorkplace);
  };

  const onAddWorkplace = () => {
    const lastIndex = formikListOfJobs.values.right?.length;

    if (!formikListOfJobs?.values?.title?.id) {
      return toast['error']('Необходимо выбрать структурное подразделение.');
    }

    formikListOfJobs.setFieldValue(`right.${lastIndex}`, {
      name: '',
      serial_number: '',
    });
    onShowAddWorkplace.on();
  };

  const onAddDivision = () => {
    onShowAddDivision.on();
  };

  return (
    <React.Fragment>
      <Styled.Wrapper>
        <Styled.TopContent aItems={'center'} paddingItem={['0 50px 0 0']}>
          <Styled.ImportRM onClick={handleUploadClick} variant={'primary'}>
            Импорт РМ
          </Styled.ImportRM>
          <Styled.DownloadFormRM>
            <a href={`/api/upload/workplace/?uid=${id}&bearer=${token}`} target={'_blank'} rel={'noreferrer'}>
              Скачать форму для заполнения перечня РМ
            </a>
          </Styled.DownloadFormRM>
        </Styled.TopContent>
        <Flex paddingItem={['0 35px 0 0', '0 0 0 35px']} wrap={'nowrap'} widthItem={['50%', '50%']}>
          <React.Fragment>
            <Styled.FlexHeader direction={'column'} wrap={'nowrap'}>
              <Styled.Title>Структурные подразделения</Styled.Title>
              <Styled.AddLine wrap={'nowrap'} paddingItem={['0 35px 0 0']} grow={[1, 1]}>
                <InputSearch placeholder={'Поиск'} onChange={onPostStructureFind} />
                <Styled.Add variant={'primary'} onClick={onAddDivision}>
                  Добавить подразделение
                </Styled.Add>
              </Styled.AddLine>
            </Styled.FlexHeader>
            <StructuralUnits
              formik={formikListOfJobs}
              onPostChangeStructure={onPostChangeStructure}
              onPostStructureWorkplace={onPostStructureWorkplace}
            />
          </React.Fragment>
          <React.Fragment>
            <Styled.FlexHeader direction={'column'} wrap={'nowrap'}>
              <TitleEdit formik={formikListOfJobs} onDelete={onDeleteStructure} onShowTitleEdit={onShowTitleEdit} />
              <Styled.AddLine wrap={'nowrap'} paddingItem={['0 35px 0 0']} widthItem={['100%']} grow={[1, 1]}>
                <InputSearch placeholder={'Поиск'} onChange={onFindWorkplace} />
                <Styled.Add variant={'primary'} onClick={onAddWorkplace}>
                  Добавить рабочее место
                </Styled.Add>
              </Styled.AddLine>
            </Styled.FlexHeader>
            <Styled.WorkplaceList>

              {right?.length

                ? right?.map((el, index) => {
                    if (!el?.name) {
                      return null;
                    }
                    //console.log(el.serial_number)
                    return (
                      <WorkplaceInput
                        key={`${el?.id}-${index}`}
                        name={`right.${index}`}
                        formik={formikListOfJobs}
                        onRightName={setRightName}
                        onDeleteStructureWorkplace={onDeleteStructureWorkplace}
                        onShowEditWorkplace={onShowEditWorkplace}
                      />
                    );
                  })
                : ''}
            </Styled.WorkplaceList>
          </React.Fragment>
        </Flex>
      </Styled.Wrapper>
      <Spinner loading={importLoading} />
      <AddWorkplace
        isRender={showAddWorkplace}
        id={id}
        formik={formikListOfJobs}
        onShowAddWorkplace={onShowAddWorkplace}
      />
      <EditWorkplace
        isRender={showEditWorkplace}
        id={id}
        formik={formikListOfJobs}
        name={rightName}
        onShowEditWorkplace={onShowEditWorkplace}
        onPostStructureWorkplace={onPostStructureWorkplace}
        onPostEditworkplace={onPostEditworkplace}
        structureEl={formikListOfJobs?.values?.structureEl}
      />
      <EditStructure
        isRender={showTitleEdit}
        id={id}
        formik={formikListOfJobs}
        name={'title'}
        onShowTitleEdit={onShowTitleEdit}
        onPostTitleEdit={onPostEditStructure}
      />
      <AddDivision
        isRender={showAddDivision}
        id={id}
        formik={formikListOfJobs}
        onPostAddStructure={onPostAddStructure}
        onShowAddDivision={onShowAddDivision}
      />
    </React.Fragment>
  );
}
