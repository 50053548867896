import styled from 'styled-components';

import Flex from '../../atoms/flex';

export const DivCenter = styled(Flex)`
width: 576px;
height: 385px;
align: left;
margin-top: 100px;
margin-left: 150px;
align-items: center;
text-align: center;
border: 1px black solid;
padding: auto;
border-radius: 15px;


`
