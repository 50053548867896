import styled from "styled-components";

export const StyledPrint = styled.button`
  background: transparent;
  border: none;

  & .label {
    padding-left: ${({ typeButton }) => (typeButton === "text" ? 0 : 8)}px;
    text-align: ${({ typeButton }) =>
      typeButton === "text" ? "center" : "left"};
    font-weight: ${({ styles }) => styles?.fontWeight ?? "400"};
    font-size: 15px;
    color: #52575c;
  }
`;
