import React from 'react';
import { WarningOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useFormik } from 'formik';

import * as hooks from '../../../../hooks';
import { convertSortDirections } from '../../../../utils/convert';
import Button from '../../../atoms/nButton';
import InputSearch from '../../../atoms/search';
import Dialog from '../../../blocks/dialog';
import Portal from '../../../blocks/portal';
import RiskAssessmentCard from '../../../blocks/riskAssessmentCard';
import Spinner from '../../../blocks/spinner';
import FieldInput from '../../fields/input';
import ClassifierMeasures from '../classifierMeasures';
import EditClassifierMeasures from '../editClassifierMeasures';
import { GeneralHazardsOrganization } from '../generalHazardsOrganization';
import { usePostProjectEvaluationcardHazard } from '../generalHazardsOrganization/hooks';
import { RiskManagementMeasures } from '../riskManagementMeasures';

import { usePostProjectRiskassessment, usePostRiskassessmentDelete } from './hooks';
import * as Styled from './styled';
import ProfRiskTable from './table';
const { useBoolean } = hooks;

export default function ProfessionalRisks(props) {
  const [modal, contextHolder] = Modal.useModal();
  const { project, onShowInfoOrganization, onPostProject } = props;
  const [showQuantityEdit, onShowQuantityEdit] = useBoolean(false);
  const [showGeneralHazardsOrganization, onShowGeneralHazardsOrganization] = useBoolean(false);
  const [showRiskManagementMeasures, onShowRiskManagementMeasures] = useBoolean(false);
  const [showEditClassifierMeasures, onShowEditClassifierMeasures] = useBoolean(false);
  const [showClassifierMeasures, onShowClassifierMeasures] = useBoolean(false);

  const [valueFind, setValueFind] = React.useState(null);
  const [rowId, setRowId] = React.useState(null);
  const [isRenderCard, onIsRenderCard] = useBoolean(false);
  const [isRenderSpinner, onIsRenderSpinner] = useBoolean(false);
  const [isFetchTable, onFetchTable] = useBoolean(false);

  const formikProfessionalRisks = useFormik({
    enableReinitialize: true,
    initialValues: {
      data: [],
      editQuantity: {},
      tableParams: {
        page: 1,
        pageSize: 50,
        field: '',
        sort: '',
      },
    },
  });

  const {
    dataRows,
    onPostProjectRiskassessment,
    onPostRiskassessmentNumber,
    onPostRiskassessmentFind,
    onPostProjectRiskassessmentDelete,
    onAutomaticEvaluation,
    onPostMeasuresExceptions,
    loadingMeasuresExceptions
  } = usePostProjectRiskassessment({
    project,
    formik: formikProfessionalRisks,
    onShowQuantityEdit,
    onIsRenderSpinner,
    onFetchTable,
  });

  const { onPostProjectEvaluationcardHazard } = usePostProjectEvaluationcardHazard({ project });

  const { onPostRiskassessmentDelete } = usePostRiskassessmentDelete({
    project,
    onPostProjectRiskassessment,
    onPostProject,
    tableParams: formikProfessionalRisks.values.tableParams,
  });

  const closeShowQuantityEdit = () => {
    formikProfessionalRisks.setFieldValue('editQuantity', null);
    onShowQuantityEdit.off();
  };

  const addShowQuantityEdit = () => {
    onPostRiskassessmentNumber({
      workplace: formikProfessionalRisks?.values.editQuantity?.id,
      quantity: Number(formikProfessionalRisks?.values.editQuantity?.quantity),
    });
  };

  const onChangeTable = (pagination, filters, sorter) => {
    //console.log(sorter)
    if (formikProfessionalRisks?.status === 'riskassessmentFind') {
      return onPostRiskassessmentFind(pagination.current)(valueFind);
    } else {

      const params = {
        page: pagination.current,
        pageSize: pagination.pageSize,
        field: sorter.columnKey,
        sort: convertSortDirections(sorter.order),
      };
      //console.log(params)
      formikProfessionalRisks.setFieldValue('tableParams', params);
      onPostProjectRiskassessment(params);
    }
  };

  const hanleChangeSearch = (event) => {
    setValueFind(event.target.value);
    onPostRiskassessmentFind(1)(event.target.value);
  };

  const handleAutomaticEvaluation = async () => {
    onIsRenderSpinner.on();
    await onAutomaticEvaluation();
    await onPostProjectEvaluationcardHazard();
  };

  const onConfirmAutomaticEvaluation = () => {
    modal.confirm({
      title: '',
      className: 'confirm-automatic-evaluation',
      icon: <WarningOutlined style={{ fontSize: 40, color: '#FFB82E' }} />,
      onOk: handleAutomaticEvaluation,
      content:
        'При выполнении автоматической оценки все изменения внесенные в карты рабочих мест будут отменены.\n' +
        'Система предзаполнит карты на основе “Базы знаний АИС”.',
      okText: 'Подтвердить',
      cancelText: 'Отмена',
    });
  };

  const onRefetchTable = () => {
    //console.log(formikProfessionalRisks.values.tableParams)
    onPostProjectRiskassessment(formikProfessionalRisks.values.tableParams);
  };

  const handleClick = () => {
    const {values, setFieldValue} = formikProfessionalRisks ?? {};
    onShowClassifierMeasures.on();

    setFieldValue('currentContMeasure', {
      name: values?.controlmeasures?.length ? values?.controlmeasures[0]?.name : null,
      parent_id: values?.controlmeasures?.length ? values?.controlmeasures[0]?.parent_id : null,
    });

    onPostMeasuresExceptions({
      parent_id: values?.controlmeasures?.length ? values?.controlmeasures[0]?.parent_id : null,
      project_id: project
    });
  }

  return (
    <React.Fragment>
      <Styled.SectionTopButton onClick={handleClick}>
        Классификатор мер управления
      </Styled.SectionTopButton>
      <div
        style={{
          width: '100%'
        }}
      >
        <Styled.SectionHead jContent={'space-between'} wrap={'nowrap'} paddingItem={['0 15px 0 0']}>
          <InputSearch placeholder={'Поиск'} onChange={hanleChangeSearch} />
          <Styled.FlexButtonSectionHead wrap={'nowrap'} paddingItem={['0 15px', '0 15px', '0 0 0 15px']}>
            <Styled.ButtonSectionHead variant={'primary'} onClick={onShowGeneralHazardsOrganization.on}>
              Заполнить перечень общих <br /> опасностей в организации
            </Styled.ButtonSectionHead>
            <Styled.ButtonSectionHead variant={'primary'} onClick={onShowRiskManagementMeasures.on}>
              Заполнить перечень общих <br /> мер управления рисками в <br /> организации
            </Styled.ButtonSectionHead>
            <Styled.ButtonSectionHead variant={'secondary'} onClick={onConfirmAutomaticEvaluation}>
              Автоматическая оценка
            </Styled.ButtonSectionHead>
          </Styled.FlexButtonSectionHead>
        </Styled.SectionHead>
        <ProfRiskTable
          data={dataRows}
          formik={formikProfessionalRisks}
          loading={isFetchTable}
          actions={{
            onShowQuantityEdit,
            onPostProjectRiskassessmentDelete,
            onPostRiskassessmentDelete,
            onShowInfoOrganization,
            setRowId,
            onIsRenderCard,
            onChangeTable,
          }}
        />
        <Portal id={'rofessionalRisksEdit'}>
          <Dialog
            isRender={showQuantityEdit}
            isClose={true}
            centered={true}
            onClose={closeShowQuantityEdit}
            title={'ИЗМЕНИТЬ КОЛИЧЕСТВО РАБОТНИКОВ'}
            settings={{
              title: {
                size: 14,
                fWeight: 600,
                lHeight: 17,
                align: 'left',
                margin: '-2px -20px 40px',
              },
              close: {
                height: 20,
                width: 20,
                right: '8px',
                top: '8px',
              },
            }}
          >
            <form>
              <FieldInput
                margin={'0 0 30px'}
                placeholder={'Количество работников'}
                formik={formikProfessionalRisks}
                name={'editQuantity.quantity'}
              />
              <Styled.Buttons jContent={'center'}>
                <Button variant={'secondary'} onClick={addShowQuantityEdit}>
                  Добавить
                </Button>
              </Styled.Buttons>
            </form>
          </Dialog>
        </Portal>
        <Portal id={'riskAssessmentCard'}>
          <RiskAssessmentCard
            isRender={isRenderCard}
            onIsRenderCard={onIsRenderCard}
            onPostProjectRiskassessment={onRefetchTable}
            //onRefetchTable={onRefetchTable}
            project={project}
            rowId={rowId}
          />
        </Portal>
        <GeneralHazardsOrganization
          project={project}
          isRender={showGeneralHazardsOrganization}
          onClose={onShowGeneralHazardsOrganization.off}
          workplace={formikProfessionalRisks?.values.editQuantity?.id}
          onRefetchTable={onRefetchTable}
        />
        <RiskManagementMeasures
          project={project}
          isRender={showRiskManagementMeasures}
          onClose={onShowRiskManagementMeasures.off}
          workplace={formikProfessionalRisks?.values.editQuantity?.id}
          onRefetchTable={onRefetchTable}
        />
        <Portal id={'classifierMeasures'}>
          <ClassifierMeasures
            title={'КЛАССИФИКАТОР МЕР УПРАВЛЕНИЯ'}
            formik={formikProfessionalRisks}
            project={project}
            isShow={showClassifierMeasures}
            onIsShow={onShowClassifierMeasures}
            showEditClassifierMeasures={showEditClassifierMeasures}
            onShowEditClassifierMeasures={onShowEditClassifierMeasures.on}
            loading={loadingMeasuresExceptions}
          />
        </Portal>
        <EditClassifierMeasures
          isRender={showEditClassifierMeasures}
          formik={formikProfessionalRisks}
          project={project}
          onPostProjectRiskassessment={onRefetchTable}
          onShowEditClassifierMeasures={onShowEditClassifierMeasures}
        />
        <Spinner isRender={isRenderSpinner} />
      </div>
      {contextHolder}
    </React.Fragment>
  );
}
