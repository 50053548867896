import styled from 'styled-components';

const getColorButton = ({ variant }) =>
  variant === 'contained' ? '#00A79D' : 'transparent';

export const StyledButton = styled.button`
  background: ${(props) => getColorButton(props)};
  font-size: ${(props) => (props.variant === 'contained' ? 20 : 15)}px;
  color: ${(props) => (props.variant === 'contained' ? '#fff' : '#101331')};
  border: 1px solid;
  border-color: ${(props) => getColorButton(props)};
  padding: 8px 15px;
  border-radius: 5px;

  &:hover {
    color: ${(props) =>
      props.variant === 'contained' ? '#00a79d' : '#101331'};
    background: transparent;
  }

  & .label {
    text-align: center;
  }

  ${({ disabled }) => (disabled ? 'opacity: 0.7; pointer-events: none;' : '')}
`;
