import React from 'react'
import {Button, Modal} from 'react-bootstrap'

import Can from "../../can";

const SimpleModal = ({
                       children,
                       show = false,
                       title,
                       onHide,
                       disabledSaveButton = false,
                       saveButtonText = 'Сохранить',
                       onSave,
                       ...otherProps
                     }) => {

  function closeHandler() {
    onSave()
    onHide()
  }

  if (!show) {
    return null;
  }

  return (
    <Modal show={show} backdrop="static" {...otherProps}>
      <Modal.Header>
        <h5 className="user-title">{title}</h5>
        <button className="btn close-button" onClick={onHide}>
          <div className="d-flex align-items-center justify-content-center"><i
            className="bi bi-x ft-sz"></i><span>Закрыть</span></div>
        </button>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Can roles={['admin-role', 'expert-role', 'analytic-role']}>
        <Modal.Footer>
          <Button variant="default" className="add-button" onClick={closeHandler}
                  disabled={disabledSaveButton}>{saveButtonText}</Button>
        </Modal.Footer>
      </Can>
    </Modal>
  )
};

SimpleModal.propTypes = {};

export default SimpleModal;
