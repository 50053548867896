import React from 'react';

import { arrLength } from '../../fp';
import { useBoolean } from '../../hooks';
import { ReactComponent as PolygonClose } from '../../image/Polygon_close.svg';
import { ReactComponent as PolygonOpen } from '../../image/Polygon_open.svg';
import SimpleModal from '../SimpleModal';

import {
  getCodeSplit,
  getRecurSearch,
  isMeasure,
  isValue,
} from './fieldSearchTree.utils';

import s from './fieldSeachTree.module.css';

function RenderListElement(props) {
  const { isSiz, el, selected, onSelect, serchValue } = props;
  const { value, code, children, name, clothes } = el ?? {};

  const [showList, onShowList] = useBoolean(false);
  React.useEffect(() => {
    if (serchValue?.length >= 3) {
      onShowList.on();
    }
  }, [serchValue, onShowList]);

  const isIconShow =
    getCodeSplit(value?.code ?? code) <= 2 && children?.length > 0;
  const IsIconPolygon = showList ? PolygonOpen : PolygonClose;

  const handleShowList = () => onShowList.toggle();
  const handleElementClick = () => {
    onSelect(el?.value ?? el);
  };

  const classNameChild = `${s.child} ${
    (el?.value?.id ?? el?.id) === selected?.id ? s.childActive : ''
  }`;

  const valueCode = isMeasure(value?.name ?? name) ? value?.code ?? code : '';
  const valueName = isMeasure(value?.name ?? name) ? value?.name ?? name : '';
  const isChildren = !!arrLength(children);
  const showClothes = isMeasure(clothes) ? `- ${clothes}` : '';
  const renderSiz = isSiz ? `${valueName} ${showClothes}` : valueName;

  if (!isValue(el)) {
    return null;
  }

  return (
    <li key={value?.id} className={s.item}>
      {isIconShow ? (
        isChildren && (
          <React.Fragment>
            <span className={s.icon} onClick={handleShowList}>
              <IsIconPolygon />
            </span>
            <span>{valueName}</span>
          </React.Fragment>
        )
      ) : (
        <div className={classNameChild} onClick={handleElementClick}>
          <span className={s.code}>{valueCode}</span>
          <span>{renderSiz}</span>
        </div>
      )}
      {showList && isChildren && (
        <RenderList list={children} selected={selected} onSelect={onSelect} />
      )}
    </li>
  );
}

function RenderList(props) {
  const { list = [], ...owenprops } = props;

  if (!list?.length) {
    return null;
  }

  if (!list?.length) {
    return null;
  }

  return (
    <ul className={s.list}>
      {list?.map((el, index) => (
        <RenderListElement
          {...owenprops}
          key={`${index}-${el?.value?.name ?? el?.name}`}
          el={el}
        />
      ))}
    </ul>
  );
}

const FieldSearchTree = ({
  placeholder = 'Введите текст',
  show,
  title,
  onHide,
  onSave,
  isSiz,
  dictionary = [],
}) => {
  const [selected, setSelected] = React.useState(null);
  const [query, setQuery] = React.useState('');

  const handleSearch = (e) => {
    const eValue = e.target.value;
    setQuery(eValue);
  };

  const handleSave = () => {
    if (!selected) {
      return null;
    }

    if (onSave) {
      onSave(selected);
      setSelected(null);
    }
    onHide();
  };

  if (!dictionary.length) {
    return null;
  }

  return (
    <SimpleModal
      show={show}
      title={title}
      onHide={onHide}
      onSave={handleSave}
      size="lg">
      <div className="modal-body">
        <form>
          <input
            className={s.inputSerach}
            type={'text'}
            placeholder={placeholder}
            onChange={handleSearch}
          />
          <div className={`${s.formContainer} shadow px-4 py-4 fz-13`}>
            <div className="cursor-pointer">
              <RenderList
                isSiz={isSiz}
                list={getRecurSearch(dictionary, query)}
                serchValue={query}
                selected={selected}
                onSelect={setSelected}
              />
            </div>
          </div>
        </form>
      </div>
    </SimpleModal>
  );
};

export default FieldSearchTree;
