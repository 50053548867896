import React from 'react';
import { useSelector } from 'react-redux';
import { AutoComplete, Input } from 'antd';
import axios from 'axios';
import { useFormik } from 'formik';
import styled from 'styled-components';

import { Button } from '../../../buttons';
import DictionaryModal from '../../../common/DictionaryModal';
import FieldSearchTree from '../../../fieldSearchTree';
import { FormikProvider } from '../../../formik/formik.context';
import Grid from '../../../grid';
import { useGetObjectOfOccurrence } from '../../../Menu/MenuOPROganization/ModalCardWorkPlace/ModalAddDangerous/modalAddDangerous.hooks';
import Table from '../../../table';

import './styles.scss';

const { TextArea } = Input;

export const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;

  .ant-select-selection-search-input,
  .ant-select-selector {
    height: 48px !important;
  }
`;

export const StyledTextArea = styled(TextArea)`
  &.ant-input {
    height: 48px;
  }
`;

const columns = [
  {
    title: 'Профессия',
    column: 'profession.name',
  },
  {
    title: ' ',
    column: 'source.name',
  },
  {
    title: 'Опасность',
    column: 'danger.name',
  },
  {
    title: 'Опасное событие',
    column: 'event.name',
    auxiliaryProps: {
      column: {
        alignText: 'right',
      },
      cell: {
        alignText: 'right',
      },
    },
  },
  // {
  //   title: 'Меры управления',
  //   column: 'managment',
  //   auxiliaryProps: {
  //     separator: {
  //       label: '/',
  //       alignLeft: '-3',
  //     },
  //   },
  // },
  {
    title: 'Частота использования в БД',
    column: 'frequency',
    auxiliaryProps: {
      cell: { alignText: 'center' },
    },
  },
  {
    title: 'Связь',
    column: 'setted',
    field: 'isNeedApproveTwo',
    auxiliaryProps: {
      formikProps: 'formikHazardMeasure',
      cell: { alignText: 'center' },
    },
  },
];

function HazardMeasure() {
  const nsiReducer = useSelector((state) => state?.NSIReducer);
  const objectOfOccurrence = useGetObjectOfOccurrence();
  const [dangerFild, setDangerFild] = React.useState(false);
  const [dangerEventField, setDangerEventField] = React.useState(false);
  const [dangerPartField, setDangerPartField] = React.useState(false);
  const [option, setOptions] = React.useState([]);

  const token = localStorage.getItem('token');

  const danger = !!nsiReducer?.danger?.length ? nsiReducer?.danger?.flat(Infinity) : [];
  const dangerEvent = [];

  const formikHazardMeasure = useFormik({
    enableReinitialize: true,
    initialValues: {
      dataFields: {
        profession: {},
        source: {},
        danger: {},
        event: {},
      },
      data: [],
    },
  });

  const { values, setFieldValue } = formikHazardMeasure;
  const { profession, event, source, danger: dangers } = values.dataFields ?? {};

  const handleDangerSave = (obj) => {
    setFieldValue('dataFields', { ...values.dataFields, danger: obj });
  };

  const handleDangerEventSave = (obj) => {
    setFieldValue('dataFields', { ...values.dataFields, event: obj });
  };

  const handleDangerPartSave = (obj) => {
    setFieldValue('dataFields', { ...values.dataFields, source: obj });
  };

  const handleSubmit = (values) => {
    const { profession, event, source, danger } = values.dataFields ?? {};

    const endpointProfession = profession?.id ? `profession=${profession?.id}` : '';
    const endpointDanger = danger?.id ? `danger=${danger?.id}` : null;
    const endpointSource = source?.id ? `source=${source?.id}` : null;
    const endpointEvent = event?.id ? `event=${event?.id}` : null;
    const endpointСollect = [endpointProfession, endpointDanger, endpointSource, endpointEvent]
      ?.filter(Boolean)
      .join('&');
    axios
      .get(`/kb/view${endpointСollect ? `?${endpointСollect}` : ''}&page=1&count=50&bearer=${token}`)
      .then((response) => {
        setOptions([]);
        setFieldValue('dataFields', {
          profession: {},
          source: {},
          danger: {},
          event: {},
        });
        setFieldValue('data', response?.data?.data);
      })
      .catch((error) => console.warn(error));
  };

  const onSearch = (val) => {
    if (!val?.length) {
      setOptions([]);
    }

    return axios
      .post(`/kb/filters?profession=${val}&bearer=${token}`)
      .then(({ data }) => {
        const options = data?.data?.map((el) => ({
          ...el,
          value: el.name,
          key: el.id,
        }));

        setOptions(options);
      })
      .catch((error) => console.warn(error));
  };

  const onSelect = (_, option) => {
    setFieldValue('dataFields', { ...values.dataFields, profession: option });
  };

  const flatArray = (arr) => {
    return arr.map(({ children }) => [...children])?.flat(Infinity);
  };

  const dangerEventCode = values.dataFields?.danger?.code
    ? flatArray(dangerEvent).filter((el) => el.value?.code === values.dataFields?.danger?.code)
    : dangerEvent;

  return (
    <FormikProvider formik={{ formikHazardMeasure }}>
      <div className="kb-hazard">
        <form>
          <Grid size={[5, 5, 5, 5]} justifyContent={'space-between'}>
            <div style={{ paddingRight: 10 }}>
              <StyledAutoComplete
                value={profession?.name}
                options={option}
                onSelect={(val, option) => onSelect(val, option)}
                onSearch={onSearch}
              />
            </div>
            <div style={{ paddingRight: 10 }}>
              <StyledTextArea
                name={'source'}
                value={`${source?.code ?? ''} ${source?.name ?? ''}`}
                onClick={() => setDangerPartField(true)}
              />
              <FieldSearchTree
                title={'Источник опасность'}
                show={dangerPartField}
                dictionary={objectOfOccurrence}
                onSave={handleDangerPartSave}
                onHide={() => {
                  setDangerPartField(false);
                }}
              />
            </div>
            <div style={{ paddingRight: 10 }}>
              <StyledTextArea
                name={'dangers'}
                value={`${dangers?.code ?? ''} ${dangers?.name ?? ''}`}
                onClick={() => setDangerFild(true)}
              />
              <FieldSearchTree
                title={'Опасность'}
                show={dangerFild}
                dictionary={danger}
                onSave={handleDangerSave}
                onHide={() => setDangerFild(false)}
              />
            </div>
            <div style={{ paddingRight: 10 }}>
              <StyledTextArea
                name={'event'}
                value={`${event?.dangerNumber ?? ''} ${event?.name ?? ''}`}
                onClick={() => setDangerEventField(true)}
              />
              <DictionaryModal
                withCode
                title={'Опасное событие'}
                show={dangerEventField}
                dictionary={dangerEventCode}
                onSave={handleDangerEventSave}
                onHide={() => setDangerEventField(false)}
                caption={'Опасное событие'}
              />
            </div>
            <div style={{ textAlign: 'right' }}>
              <Button label={'Проверить'} variant={'contained'} onClick={() => handleSubmit(values)} />
            </div>
          </Grid>
        </form>
        <div style={{ paddingTop: 15 }}>
          <Table columns={columns} data={values?.data ?? []} numberOfcolumns={7} numberOfData={11} />
        </div>
      </div>
    </FormikProvider>
  );
}

export default HazardMeasure;
