import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';

import useKeyEvents from '../../../../hooks/useEventKey';
import { csrfWithLogin } from '../../../../store/actions/authActions';
import Dialog from '../../../blocks/dialog';

import './styles.scss';

export default function Login() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userName = Form.useWatch('userName', form);
  const password = Form.useWatch('password', form);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      await dispatch(
        csrfWithLogin(
          {
            userName: values.userName,
            password: values.password,
          },
          () => setLoading(false)
        )
      );
    } catch (error) {
      setLoading(false);
      if (error?.data?.status === 'error' && error?.data?.type === 'password') {
        //formik?.setFieldValue('password', '');
      }

      console.warn('error', error.data.status);
    }
  };

  const onFinish = async (values) => {
    await onSubmit(values);
  };

  useKeyEvents('Enter', () => {
    const values = form.getFieldsValue();
    onSubmit(values);
  });

  const handleClickRegister = () => navigate('/register');

  const disabled = !userName || !password;

  return (
    <Dialog isRender={true} isClose={false} title={'Авторизация'} centered={true} withOutOverflow>
      <Form className="auth-form" form={form} requiredMark={false} layout="vertical" name="auth" onFinish={onFinish}>
        <Form.Item name="userName">
          <Input placeholder="Логин" size="large" />
        </Form.Item>
        <Form.Item name="password">
          <Input.Password placeholder="Пароль" size="large" />
        </Form.Item>
        <Link className="auth-form__link" to={'/recover-account'}>
          Восстановить аккаунт
        </Link>
        <div className="auth-form__footer">
          <Button size="large" type="default" htmlType="button" onClick={handleClickRegister}>
            Регистрация
          </Button>
          <Button size="large" type="primary" htmlType="submit" loading={loading} disabled={disabled}>
            Отправить
          </Button>
        </div>
      </Form>
    </Dialog>
  );
}
