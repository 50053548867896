import React from "react";

import { StyledGrid, StyledItem } from "./grid.styled";

function GridItem(props) {
  const { children, ...owenprops } = props;

  return <StyledItem {...owenprops}>{children}</StyledItem>;
}

export default function Grid(props) {
  const {
    background,
    size = 0,
    grow = 0,
    shrink = 0,
    justifyContent,
    children,
  } = props;

  return (
    <StyledGrid justifyContent={justifyContent} background={background}>
      {React.Children.toArray(children).map((child, i) => (
        <GridItem
          size={size[i]}
          grow={grow[i]}
          shrink={shrink[i]}
          key={`${size[i]}-${i}`}
        >
          {child}
        </GridItem>
      ))}
    </StyledGrid>
  );
}
